import {
  HiOutlineUserGroup,
  HiOutlinePlusSm,
  // HiOutlineHome,
  HiOutlineFolderOpen,
  HiCreditCard
} from "react-icons/hi";
import { HiOutlineWallet } from "react-icons/hi2";
import useAdminAuth from "../../../../hooks/zustand/admin/useAdminAuth";
// import { AiOutlineSetting } from 'react-icons/ai';
import { useMemo } from "react";

export const MenuAdmin = () => {
  const info = useAdminAuth();

  const x = info.admin?.access;

  const listMenu = [
    {
      key: "maintenance",
      menu: "Maintenance",
      label: "Database",
      icon: <HiOutlineUserGroup />,
      subMenuItems: [
        {
          key: "database-listing",
          label: "Database Listing",
          path: "/admin/admin-database-list",
          ac: "DatabaseListing",
        },
         {
           key: 'databaseBackupLog',
           label: 'database backup log',
           path: '/admin/admin-backuplog-list',
         },
      
      ],
    },
  ];

  const updatedListMenu = useMemo(() => {
    return listMenu
      .map((menuItem) => {
        if (x?.hasOwnProperty(menuItem.ac)) {
          if (!x[menuItem.ac]) {
            return null;
          }
        }
        if (menuItem.subMenuItems && menuItem.subMenuItems.length > 0) {
          const updatedSubMenuItems = menuItem.subMenuItems
            .map((subMenuItem) => {
              if (x?.hasOwnProperty(subMenuItem.ac)) {
                if (!x[subMenuItem.ac]) {
                  return null;
                }
              }
              return subMenuItem;
            })
            ?.filter(Boolean);

          menuItem.subMenuItems = updatedSubMenuItems;
        }
        if (menuItem.menuItems && menuItem.menuItems.length > 0) {
          const updatedMenuItems = menuItem.menuItems
            .map((menuItem) => {
              if (x?.hasOwnProperty(menuItem.ac)) {
                if (!x[menuItem.ac]) {
                  return null;
                }
              }
              return menuItem;
            })
            ?.filter(Boolean);

          menuItem.menuItems = updatedMenuItems;
        }
        return menuItem;
      })
      ?.filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x]);

  return updatedListMenu;
};
