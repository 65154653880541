import { create } from 'zustand';
import { Api } from '../../../lib/api/axios';
import useAdminAuth from '../admin/useAdminAuth';
import { assertUnaryExpression } from '@babel/types';

const useMemberListing = create((set, get) => ({
  data: [],
  listMemberTotalRows: null,
  getData: async (
    searchTerm = '',
    page = 1,
    perPage = 10,
    sort = '',
    sortType = '',
    startDate = ''
  ) => {
    try {
      const res = await Api.get(
        `/cms/list-member?handler=${
          useAdminAuth.getState().admin?.role === 'master' ||
          useAdminAuth.getState().admin?.role === 'admin'
            ? ''
            : useAdminAuth.getState().admin?._id
        }&searchTerm=${searchTerm}&page=${page}&limit=${perPage}&sort=${sort}&sortType=${sortType}&start=${startDate}`
      );
      console.log(res);
      set(() => ({
        data: res.data?.docs,
        listMemberTotalRows: res.data?.totalDocs,
      }));
      return res.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  getDataById: async(id) => {
    try{
      const res = await Api.get(`/cms/list-member/${id}`);
      return res.data;
    }
    catch(error){
      return error;
    }
  },
  updateData: async (payload, controller) => {
    try {
      console.log("payload",payload);
      const res = await Api.patch('/cms/update-member', payload);
      // await get().getData(
      //   useAdminAuth.getState().admin?.role === 'master' ||
      //     useAdminAuth.getState().admin?.role === 'admin'
      //     ? ''
      //     : useAdminAuth.getState().admin?._id,
      //   '',
      //   1,
      //   10
      // );
      await get().getData(
        controller.search,
        controller.page,
        controller.perPage
      );
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  },
  resetPassword: async (payload) => {
    try {
      const res = await Api.patch('/cms/reset-password', payload);
      return res.status;
    } catch (error) {
      return error?.response?.data;
    }
  },
  createNew: async (payload, controller) => {
    try {
      const res = await Api.post('/cms/list-member', payload);
      await get().getData(
        controller.search,
        controller.page,
        controller.perPage
      );
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  },
  updateMember: async (payload, id, controller) => {
    try {
      const res = await Api.patch(`/cms/list-member/${id}`, payload);
      await get().getData(
        controller.search,
        controller.page,
        controller.perPage
      );
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  },
  createAnonymousMember: async (payload)=>{
    try{
      const res = await Api.post(`/cms/list-member/create-anonymous`, payload);
      return res;
    }
    catch(error){
      return error?.response?.data;
    }
  },
  deleteMember: async (id, controller) => {
    try {
      console.log("controller", controller);
      const res = await Api.delete(`cms/list-member/${id}`);
      await get().getData(
        controller.search,
        controller.page,
        controller.perPage
      );
      return res;
    }
    catch(error){
      return error?.response?.data;
    }
  }
}));

export default useMemberListing;
